import React from 'react'
import avatar2 from '../../../assets/images/avatar2.png'
import './index.scss'

const Logo = () => {
  return (
    <div className="logo-container">
      <img className="solid-logo" src={avatar2} alt="React, Developer" />
    </div>
  )
}

export default Logo
